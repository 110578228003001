import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import variables from "Styles/variables.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useInstrumentContext } from "Hooks/InstrumentContext";
import { useServices } from "Hooks/ServicesContext";
import { useQuery, useQueryClient } from "react-query";
import { ROLES } from "Router/Routes";
import { useUserContext } from "Hooks/UserContext";
import { useLocation } from "react-router-dom";

const property = { md: 0, lg: 1, xl: 1 };
//const options = ["Option 1", "Option 2"];
/**
 *
 * @returns  Fund component
 */
export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  marginRight: "auto",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto"
  }
}));
export const StyledAutoCompleteBase = styled(Autocomplete)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    width: "200px"
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0",
    width: "150px"
  },
  background: theme.palette.mode === "dark" ? variables.boxDarkBg : "none",

  ".MuiInputBase-root": {
    padding: theme.spacing(0),
    outline: "none",
    ".MuiAutocomplete-endAdornment": {
      top: "calc(50% - 11px)"
    },

    ".MuiAutocomplete-input": {
      padding: "10px",
      color: theme.palette.mode === "dark" ? variables.colorWhite : variables.colorWhite,
      fontWeight: variables.fontWeight400,
      fontSize: variables.headingFontSize
    }
  },
  ".MuiAutocomplete-popupIndicator": {
    transform: "none",
    color: variables.colorWhite
  },
  ".MuiFormLabel-root": {
    top: "-5px",
    color: theme.palette.mode === "dark" ? variables.secondary : variables.colorWhite
  },
  ".MuiFormLabel-root.Mui-focused": {
    color: theme.palette.mode === "dark" ? variables.secondary : variables.colorWhite,
    border: "none"
  },
  ".MuiFormLabel-root[data-shrink=true]": {
    top: "3px"
  },
  fieldset: {
    borderColor: theme.palette.mode === "dark" ? "transparent" : variables.colorWhite
  }
}));
function FundData() {


  const [instrumentList, setInstrumentList] = useState([]);
  const { apiService } = useServices();
  const { loggedIn, userDetails, activeInstrument } = useUserContext();
  const [fundData, setFundData] = React.useState("");
  //get All Instruments
  const { data } = useQuery(["getAllInstruments"], () => apiService.getAllInstruments());
  const [selectedInstrument, setSelectedInstrument] = React.useState("");
  // React query Client
  const queryClient = useQueryClient();
  //active instrument Id Global Callback
  const { setActiveInstrumentID, setActiveQuoteID } = useInstrumentContext();
  const { pathname } = useLocation();

  // get fund Data for login user
  const { isLoading } = useQuery("getUserFundData", () => apiService.getUserFundData(), {
    enabled: false,
    onSuccess: (data) => {
      setFundData(data);
    }
  });

  // on load get data for fund Details
  useEffect(() => {
    // if user is logged In and fund Data is not available
    if (loggedIn) {
      setActiveInstrumentID(activeInstrument)
      setActiveQuoteID(null);
      queryClient.prefetchQuery(["getUserFundData"], () => apiService.getUserFundData());
    }
  }, []);

  //set Instrument List
  useEffect(() => {
    if (data) {
      let updatedData =
        data &&
        data.length > 0 &&
        data?.map((item) => {
          if (item.instrumentId === activeInstrument) {
            setSelectedInstrument(item.ticker)
          }
          return {
            label: item.ticker,
            id: item.instrumentId
          };
        });
      setInstrumentList(updatedData || []);
    }
  }, [data]);

  //Instrument Change Callback and set active instrument Id Globally
  const onChangeHandler = (event, newValue) => {
    setActiveInstrumentID(newValue?.id); // Set instrument id on instrument context
    setActiveQuoteID(null);
  };

  return (
    <Box
      className="meta-data"
      sx={{
        flexGrow: 1,
        gap: 2,
        display: { xs: "flex", md: "flex" },
        justifyContent: "end",
        alignItems: "center",
        mr: 3
      }}>
      {loggedIn && userDetails && userDetails.role != ROLES.DEALER && pathname !== "/history" && (
        <Search sx={{ display: { xs: "none", md: "flex" } }}>
          <Typography variant="span" sx={{ color: variables.colorwhite, mr: 2 }}>
            Instrument:
          </Typography>
          {instrumentList && instrumentList.length < 1 ? (
            // Render a TextField with defaultValue when instrumentList is empty
            <StyledAutoCompleteBase
              inputValue={selectedInstrument}
              onChange={onChangeHandler}
              freeSolo
              popupIcon={<SearchIcon />}
              id="controllable-states-demo"
              options={instrumentList}
              disabled
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="No Instrument Found" />}
            />
          ) : (
            <StyledAutoCompleteBase
              inputValue={selectedInstrument}
              onInputChange={(event, newInputValue) => {
                setSelectedInstrument(newInputValue);
              }}
              onChange={onChangeHandler}
              freeSolo
              popupIcon={<SearchIcon />}
              id="controllable-states-demo"
              options={instrumentList}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Search..." />}
            />
          )}
        </Search>
      )}
      <Typography variant="body2">
        Base Currency{" "}
        <Typography sx={{ ml: property }} variant="string" className="crncy color-white">
          {" "}
          USD
        </Typography>{" "}
      </Typography>
      <Typography variant="body2" sx={{ display: { xs: "none", md: "flex" } }}>
        Total Balance{" "}
        <Typography sx={{ ml: property }} variant="string" className="blnmc color-green">
          {" "}
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : fundData && fundData?.balance && !isNaN(fundData.balance) ? (
            `$ ` + fundData.balance
          ) : (
            "$2873.93 "
          )}
        </Typography>
      </Typography>
      <Typography variant="body2" sx={{ display: { xs: "none", md: "flex" } }}>
        Available Funds{" "}
        <Typography sx={{ ml: property }} variant="string" className="crncy color-green">
          {" "}
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : fundData && fundData?.availableFunds && !isNaN(fundData.availableFunds) ? (
            `$ ` + fundData.availableFunds
          ) : (
            "$2757.75 "
          )}
        </Typography>
      </Typography>
    </Box>
  );
}

export default FundData;
